import React 				from 'react'
import ReactDOM         	from 'react-dom'
import requestFrame    		from 'request-frame'
import getNodeDimensions 	from 'get-node-dimensions'
import classnames 			from 'classnames'
import { compose } 			from 'redux'
import { connect }      	from 'react-redux'
import PS 					from 'publish-subscribe-js'
import {
	lerp,
	inverseLerp
} from 'canvas-sketch-util/math'
import {
	keys,
	isEqual,
	isFunction,
	isFinite
} from 'lodash'
import Measure 				from 'react-measure'

import FixedScrollEl 		from '../fixed-scroll-el'

import {
	RESIZE,
	SCROLL
} from '../../lib/pubSubEvents'

import {
	getFixedStyle
} from '../../lib/_helpers'
import {
	roundTo
} from '../../lib/mathHelpers'

class SplitScroll extends React.Component {

	constructor(props) {
		super(props)

		this.checkSizeUpdate = this.checkSizeUpdate.bind(this)

		this.state = {
			containerDimensions: {
				left: 0,
				right: 100,
				width: 100
			},
			sizes: {
				left: {
					height: 0
				},
				right: {
					height: 0
				}
			}
		}
	}

	componentDidMount(){
		this.resizeKey = PS.subscribe(RESIZE, this.checkSizeUpdate)

		this.checkSizeUpdate()
	}

	componentWillUnmount() {
		if(this.resizeKey){ PS.unsubscribe(RESIZE, this.resizeKey) }
	}

	componentDidUpdate(prevProps, prevState){
		this.updateDimensions(prevState.containerDimensions)
	}

	checkSizeUpdate(){
		this.updateDimensions()
	}

	updateDimensions(prevDimensions = null){
		if(this.containerEl){
			const containerEl = ReactDOM.findDOMNode(this.containerEl)
			const newContainerDimensions = getNodeDimensions(containerEl)

			let _prevDimensions = prevDimensions
			if(!_prevDimensions){
				_prevDimensions = this.state.containerDimensions
			}

			if(!isEqual(_prevDimensions, newContainerDimensions)){
				this.setState({
					containerDimensions: newContainerDimensions
				})
			}
	    }
	}

	updateBounds(key, dimensions){
		const {
			sizes
		} = this.state
		const {
			onChildRelayout
		} = this.props

		const _sizes = Object.assign({}, sizes, {
			[key]: dimensions
		})

		this.setState({
			sizes: _sizes
		})

		if(onChildRelayout && isFunction(onChildRelayout)){
			onChildRelayout()
		}
	}

	calcStyles(){
		const {
			containerDimensions,
			sizes
		} = this.state
		const {
			fixedStatus,
			percScrolled,
			screenSize
		} = this.props

		const offset = this.offset()
		const maxHeight = Math.max(sizes.left.height, sizes.right.height)
		const windowHeight = Math.min(maxHeight, screenSize.height - offset)

		return {
			leftMargin: containerDimensions.left,
			rightMargin: screenSize.width - containerDimensions.right,
			containerWidth: containerDimensions.width,
			windowHeight: windowHeight,
			totalHeight: maxHeight,
			leftHeight: sizes.left.height,
			rightHeight: sizes.right.height
		}
	}

    offset(){
    	const {
    		headerHeight,
    		offset
    	} = this.props

    	return isFinite(offset) ? offset : headerHeight
    }

	render() {
		const {
			sizes
		} = this.state
		const {
			trackToBottom,
			fixedStyle,
			percScrolled,
			percScrolledBottom,	
			leftEl,
			rightEl,
			screenSize
		} = this.props

		const offset = this.offset()
		const styles = this.calcStyles()

		const sides = [
			{
				side: 'left',
				el: leftEl,
				width: styles.containerWidth * 0.5,
				height: styles.leftHeight,
				fixedMargin: styles.leftMargin
			},
			{
				side: 'right',
				el: rightEl,
				width: styles.containerWidth * 0.5,
				height: styles.rightHeight,
				fixedMargin: styles.rightMargin
			}
		]

		return <div
			ref={ (el) => { this.containerEl = el } }
			className="splitScroll"
			style={{
				position: 'relative',
				height: styles.totalHeight
			}}>
			{ sides.map((_side, _i) => {
				return <Measure
					key={ _i }
				    client
					onResize={ (dimensions) => {
						this.updateBounds(_side.side, dimensions.client)
					} }
					>
				    { ({ measureRef }) => {
				    	const _margin = fixedStyle.position === 'fixed' ? _side.fixedMargin : 0
				    	const _sideProp = _side.side
				    	let _translate = null
				    	let _style = { ...fixedStyle }
				    	let _height = _side.height

				    	if(trackToBottom && _side.height < styles.windowHeight){
				    		let _position = null
				    		if(percScrolledBottom <= 0){
				    			_position = 'top'
				    		} else if(percScrolledBottom < 1){
				    			_position = 'fixed'
				    		} else {
				    			_position = 'bottom'
				    		}

				    		if(_position === 'fixed'){
				    			const _fromTop = percScrolledBottom * styles.totalHeight
				    			if(_fromTop >= styles.totalHeight - _side.height){
				    				_position = 'bottom'
				    			}
				    		}

				    		_style = Object.assign(_style, getFixedStyle(_position, offset))
				    	} else {
				    		const _diff = Math.max(0, _side.height - styles.windowHeight)
				    		let _y = 0
				    		if(_style.position === 'absolute' && _style.bottom === 0){
				    			_y = 0
				    			_height = Math.max(_side.height, styles.windowHeight)
				    		} else {
				    			_y = roundTo(lerp(0, _diff * -1, percScrolled), 100)
				    		}
				    		_translate = `translate3d(0,${ _y }px,0)`
				    	}

						return <div className="splitScroll-side"
							style={{
								[_sideProp]: _margin,
								width: _side.width,
								height: _height,
								..._style
							}}>
							<div
								className="splitScroll-side-content"
								style={{
									WebkitTransform: _translate,
									transform: _translate
								}}
								ref={ measureRef }>
								{ _side.el ? _side.el : null }
							</div>
						</div>
					} }
				</Measure>
			}) }
		</div>
	}
}

const mapStateToProps = (state) => {
    return {
    	headerHeight: state.ui.header.height,
        screenSize: state.ui.screenSize
    }
}

export default FixedScrollEl(connect(mapStateToProps, null)(SplitScroll))