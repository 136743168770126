import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import moment from 'moment'

import PageLayout from '../components/page-layout'
import Story from '../components/story'
import { getSEOFromPage } from '../components/seo'

import breakpoints from '../lib/constants/breakpoints.json'

class SinglePost extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { data, screenSize, location } = this.props

    return (
      <PageLayout
        seo={getSEOFromPage(get(data, 'post'))}
        location={location}
        showFooter={true}
      >
        <Story story={data.post} />
      </PageLayout>
    )
  }
}

const mapStateToProps = (state) => {
  const { ui } = state

  return {
    screenSize: ui.screenSize,
  }
}

export default connect(mapStateToProps)(SinglePost)

export const query = graphql`
  query($id: String!) {
    post: wordpressPost(id: { eq: $id }) {
      title
      id
      wordpress_id
      slug
      path
      link
      date(formatString: "YYYY MMMM DD")
      seo_meta {
        title
        description
      }
      acf {
        gallery {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 980) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          caption
        }
        introduction
        story_content_post {
          __typename
          ... on WordPressAcf_story_text {
            text
          }
          ... on WordPressAcf_story_quote {
            quote
            from
          }
          ... on WordPressAcf_story_subheading {
            title
            subtitle
          }
          ... on WordPressAcf_story_typeform {
            typeform_url
          }
        }
        footer_details
      }
      categories {
        id
        name
      }
    }
  }
`
