import React from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { get, findIndex, isFinite, filter, each } from 'lodash'
import { graphql, StaticQuery, Link } from 'gatsby'

import { Grid } from '../grid'
import FixedScrollEl from '../fixed-scroll-el'
import SectionWrapper from '../section/wrapper'
import { ImageGalleryWithBreadcrumbs } from '../image-gallery'
import SplitScroll from '../split-scroll'
import { ScrollTextEl } from '../section/text-el'

import StoryImages from './images'
import StoryText from './text'

import breakpoints from '../../lib/constants/breakpoints.json'
import {
  formatTitle,
  storyPrefix,
  mapStories,
  renderHTML,
} from '../../lib/_helpers'
import { formatLink } from '../../lib/formatLink'
import { ThumbnailImageFragment } from '../../lib/fragments'

const adjacentLink = (index, shift, stories) => {
  if (!stories || stories.length <= 0 || !isFinite(index) || !isFinite(shift)) {
    return null
  }

  let _index = index + shift
  if (_index < 0) {
    _index = stories.length - 1
  } else if (_index >= stories.length) {
    _index = 0
  }

  const story = stories[_index]
  const slug = get(story, 'node.path')
  return slug ? formatLink(slug, 'post') : null
}

const StoryLink = ({ link, title }) => {
  return (
    <Link to={link}>
      <span>{title}</span>
    </Link>
  )
}

class Story extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      readingTime: null,
    }
  }

  componentDidMount() {
    this.calcReadingTime()
  }

  calcWords(str) {
    return str ? str.split(' ').length : 0
  }

  calcReadingTime() {
    const { story } = this.props

    const rows = get(story, 'acf.story_content_post')

    let wordCount = 0
    each(rows, (_row, _i) => {
      switch (_row.__typename) {
        case 'WordPressAcf_story_text':
          wordCount += this.calcWords(_row.text)
          break
        case 'WordPressAcf_story_quote':
          wordCount += this.calcWords(_row.quote)
          break
        case 'WordPressAcf_story_subheading':
          wordCount += this.calcWords(_row.title)
          break
      }
    })

    let readingTime = Math.ceil(wordCount / 275)
    if (readingTime > 10) {
      readingTime = Math.ceil(readingTime / 5) * 5
    }
    this.setState({
      readingTime: readingTime,
    })
  }

  render() {
    const { readingTime } = this.state
    const {
      story,
      screenSize,
      headerHeight,
      prevLink,
      nextLink,
      relatedStories,
      fixedStyle,
    } = this.props

    const fixedHeight = screenSize.height - headerHeight

    const prefix = storyPrefix(story)
    const title = story.title

    const columnStyle = Object.assign(
      {
        height: fixedHeight,
      },
      fixedStyle
    )

    const introduction = get(story, 'acf.introduction')
    const rows = get(story, 'acf.story_content_post')
    const footer = get(story, 'acf.footer_details')
    const images = get(story, 'acf.gallery')

    const hasSideColumns = screenSize.width > breakpoints.tabletPortrait
    const isStacked = screenSize.width <= breakpoints.mobile

    const _relatedStories =
      isStacked && relatedStories ? relatedStories.slice(0, 2) : relatedStories

    return (
      <SectionWrapper naturalHeight={true}>
        <div className="sectionSpacer" />
        <div
          className="story txt--purple"
          style={{
            minHeight: fixedHeight,
          }}
        >
          {hasSideColumns ? (
            <div
              className="story-column story-column--prev"
              style={columnStyle}
            >
              {prevLink ? (
                <StoryLink link={prevLink} title="Previous story" />
              ) : null}
            </div>
          ) : null}
          <div className="story-content">
            <div className="story-title">
              <div className="textBlock textBlock--center textBlock--small">
                {prefix ? <h6>{renderHTML(prefix)}</h6> : null}
                {title ? <h1 className="p--h3">{formatTitle(title)}</h1> : null}
                {isFinite(readingTime) && readingTime > 0 ? (
                  <p>{readingTime} minute read</p>
                ) : null}
              </div>
            </div>
            <div
              className={classnames('story-body', {
                'story-body--stacked': isStacked,
              })}
            >
              {isStacked ? (
                <React.Fragment>
                  {images && images.length > 0 ? (
                    <StoryImages images={images.slice(0, 1)} />
                  ) : null}
                  <StoryText
                    introduction={introduction}
                    rows={rows}
                    footer={footer}
                  />
                  {images && images.length > 1 ? (
                    <StoryImages images={images.slice(1)} />
                  ) : null}
                </React.Fragment>
              ) : (
                <SplitScroll
                  leftEl={<StoryImages images={images} />}
                  rightEl={
                    <StoryText
                      introduction={introduction}
                      rows={rows}
                      footer={footer}
                    />
                  }
                />
              )}
            </div>
            {!hasSideColumns && nextLink ? (
              <Link className="story-bar story-nextLink" to={nextLink}>
                <span>Next story</span>
              </Link>
            ) : null}
            {_relatedStories && _relatedStories.length > 0 ? (
              <div className="story-footer">
                <div className="story-footer-cont cont">
                  <div className="sectionSpacer" />
                  <ScrollTextEl bodyStyling={true} className="txt--purple">
                    <p>Related stories.</p>
                  </ScrollTextEl>
                  <div className="sectionSpacer sectionSpacer--half" />
                  <Grid
                    colBase={isStacked ? 2 : 3}
                    title="Stories."
                    colour="purple"
                    textBlockClassName="textBlock--small"
                    elementCTA="Read more"
                    elements={mapStories(_relatedStories)}
                    bodyIsHTML={true}
                    ignoreFeatured={true}
                  />
                  <div className="sectionSpacer" />
                </div>
              </div>
            ) : null}
            <Link className="story-bar story-backLink" to="/stories/">
              <span>Back to stories</span>
            </Link>
          </div>
          {hasSideColumns ? (
            <div
              className="story-column story-column--next"
              style={columnStyle}
            >
              {nextLink ? (
                <StoryLink link={nextLink} title="Next story" />
              ) : null}
            </div>
          ) : null}
        </div>
      </SectionWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  const { ui } = state

  return {
    headerHeight: ui.header.height,
    screenSize: ui.screenSize,
  }
}

const ReduxStory = FixedScrollEl(connect(mapStateToProps, null)(Story))

export default (props) => {
  const { story } = props

  return (
    <StaticQuery
      query={graphql`
        query {
          stories: allWordpressPost(
            sort: { fields: [date], order: DESC }
            filter: { acf: { hide_from_stories: { eq: false } } }
          ) {
            edges {
              node {
                title
                id
                wordpress_id
                slug
                path
                link
                date(formatString: "YYYY MMMM DD")
                acf {
                  thumbnail {
                    ...ARYImageThumbnail
                  }
                  featured_article
                  introduction
                  hide_from_stories
                }
                categories {
                  id
                  name
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const _stories = get(data, 'stories.edges')
        const stories = _stories
          ? filter(_stories, (_story, _i) => {
              return !get(_story, 'node.acf.hide_from_stories')
            })
          : null

        const index = stories
          ? findIndex(stories, (_story, _i) => {
              return _story.node.wordpress_id === story.wordpress_id
            })
          : null

        const prevLink = adjacentLink(index, -1, stories)
        const nextLink = adjacentLink(index, 1, stories)

        let relatedStories = stories
          ? filter(stories, (_story, _i) => {
              return _story.node.wordpress_id !== story.wordpress_id
            })
          : null
        if (relatedStories && relatedStories.length > 3) {
          relatedStories = relatedStories.slice(0, 3)
        }

        return (
          <ReduxStory
            prevLink={prevLink}
            nextLink={nextLink}
            relatedStories={relatedStories}
            {...props}
          />
        )
      }}
    />
  )
}
