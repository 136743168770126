import React 				from 'react'
import ReactDOM         	from 'react-dom'
import getNodeDimensions 	from 'get-node-dimensions'
import { compose } 			from 'redux'
import { connect }      	from 'react-redux'
import PS 					from 'publish-subscribe-js'
import {
	inverseLerp
} from 'canvas-sketch-util/math'
import {
	keys
} from 'lodash'

import {
	inView
} from '../scroll-in-viewport'

import {
	RESIZE,
	SCROLL
} from '../../lib/pubSubEvents'
import {
	getFixedStyle
} from '../../lib/_helpers'

function FixedScrollEl(WrappedComponent) {
	return class extends React.Component {

		constructor(props) {
			super(props)

			this.checkScrollUpdate = this.checkScrollUpdate.bind(this)
			this.onChildRelayout = this.onChildRelayout.bind(this)

			this.state = {
				scrollInView: false,
				fixedStatus: 'top',
				percScrolled: 0,
				percScrolledBottom: null
			}
		}

		componentDidMount(){
			this.resizeKey = PS.subscribe(RESIZE, this.checkScrollUpdate)
			this.scrollKey = PS.subscribe(SCROLL, this.checkScrollUpdate)

			this.checkScrollUpdate()
		}

		componentWillUnmount() {
			if(this.resizeKey){ PS.unsubscribe(RESIZE, this.resizeKey) }
			if(this.scrollKey){ PS.unsubscribe(SCROLL, this.scrollKey) }

			this.clearRelayoutTO()
		}

		checkScrollUpdate(){
			const {
				fixedStatus,
				percScrolled,
				percScrolledBottom,
				scrollInView
			} = this.state
			const {
				screenSize,
				trackToBottom
			} = this.props

			if(!this.containerEl){ return }

	    	const wrapEl = ReactDOM.findDOMNode(this.containerEl)
	    	const wrapElDimensions = getNodeDimensions(wrapEl)
	    	const offset = this.offset()

	    	const min = offset
	    	let max = screenSize.height - wrapElDimensions.height

			let newFixedStatus = 'top'
	    	if(wrapElDimensions.top <= max){
	    		newFixedStatus = 'bottom'
	    	} else if(wrapElDimensions.top <= min){
	    		newFixedStatus = 'fixed'
	    	}

	    	const newPercScrolled = Math.max(0,
	    		Math.min(
	    			1,
	    			inverseLerp(min, max, wrapElDimensions.top)
	    		)
	    	)
	    	let newPercScrolledBottom = null
	    	if(trackToBottom){
	    		newPercScrolledBottom = Math.max(0,
		    		Math.min(
		    			1,
		    			inverseLerp(min, offset - wrapElDimensions.height, wrapElDimensions.top)
		    		)
		    	)
	    	}

	    	const newScrollInView = inView(wrapEl, screenSize)

	    	let newState = {}

	    	const stateChecks = [
	    		{
	    			key: 'fixedStatus',
	    			p: fixedStatus,
	    			n: newFixedStatus
	    		},
	    		{
	    			key: 'percScrolled',
	    			p: percScrolled,
	    			n: newPercScrolled
	    		},
	    		{
	    			key: 'percScrolledBottom',
	    			p: percScrolledBottom,
	    			n: newPercScrolledBottom
	    		},
	    		{
	    			key: 'scrollInView',
	    			p: scrollInView,
	    			n: newScrollInView
	    		}
	    	]

	    	for(let i = 0; i < stateChecks.length; i++){
	    		const _check = stateChecks[i]
	    		if(_check.p !== _check.n){
	    			newState[_check.key] = _check.n
	    		}
	    	}

	    	if(keys(newState).length > 0){
	    		this.setState(newState)
	    	}
	    }

	    offset(){
	    	const {
	    		headerHeight,
	    		offset
	    	} = this.props

	    	return isFinite(offset) ? offset : headerHeight
	    }

	    onChildRelayout(){
	    	this.setRelayoutTO()
	    }

	    setRelayoutTO(){
	    	this.clearRelayoutTO()

	    	this.relayoutTO = setTimeout(() => {
	    		this.checkScrollUpdate()
	    	}, 100)
	    }

	    clearRelayoutTO(){
	    	if(this.relayoutTO){ clearTimeout(this.relayoutTO) }
	    }

		render() {
			const {
				scrollInView,
				fixedStatus,
				percScrolled,
				percScrolledBottom
			} = this.state

			const offset = this.offset()

			return <div
				ref={ (el) => { this.containerEl = el } }>
				<WrappedComponent
					{ ...this.props }
					fixedStyle={ getFixedStyle(fixedStatus, offset) }
					percScrolled={ percScrolled }
					percScrolledBottom={ percScrolledBottom }
					scrollInView={ scrollInView }
					offset={ offset }
					onChildRelayout={ this.onChildRelayout() }
					/>
			</div>
		}
	}
}

const mapStateToProps = (state) => {
    return {
    	headerHeight: state.ui.header.height,
        screenSize: state.ui.screenSize
    }
}

export default compose(
	connect(mapStateToProps, null),
	FixedScrollEl
)