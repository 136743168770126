import React                from 'react'
import ReactDOM             from 'react-dom'
import classnames           from 'classnames'
import {
	get
} from 'lodash'

import InlineImage 			from '../inline-image'

import {
	formatImageForARYImage
} from '../../lib/_helpers'

class StoryImages extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const {
			images
		} = this.props

		return <div className="storyImages">
			{ images && images.length > 0 ? images.map((_image, _i) => {
				const _imageObj = formatImageForARYImage(_image.image)
				return <div
					key={ _i }
					className="storyImages-image">
					{ _imageObj ? <InlineImage
						image={ _imageObj } /> : null }
					{ _image.caption ? <div className="storyImages-image-caption">{ _image.caption }</div> : null }
				</div>
			}) : null }
		</div>

	}
}

export default StoryImages